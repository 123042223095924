.App {
  text-align: center;
  background : white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-header {
  background-color: lightblue;
  display: flex;
  align-items: center;
}


.accordion-button,
.accordion-button:not(.collapsed) {
    padding: 20px;
    background: rgba(128,128,128,0.5);
    color: white;
}
.accordion-button:focus {
  background: rgba(65,165,29,1);
  color: white;
}

.accordion-button:after {
  margin-left : 1rem;
}

.proposal-status  {
  margin : 1rem;
  border-radius : 1rem;
  border : 1px solid white;
  font-size : 1.2rem;
}

.proposal-date {
  background : rgba(0,255,0,0.2);
  padding : 1rem;
}

.proposal-viewed {
  background : rgba(0,0,255,0.2);
  padding : 1rem;
}

.proposal-interview {
  background : rgba(255,0,0,0.2);
  padding : 1rem;
}
.myButton { 
  background : 'red'
}

